@import "src/scss/abstracts/variables";
@import "src/scss/abstracts/mixins";

/* styles for capital programs list view */
.capital-programs-list {
	&__rotator {
		&--hidden {
			display: none;
		}

		.slick-list {
			overflow: visible !important;
		}

		.slick-track {
			display: flex !important;
		}

		.slick-slide {
			height: inherit !important;
			margin: 0 25px;

			@include for-size(phone-down) {
				margin: 0 15px;
			}

			> div {
				height: 100%;
			}
		}

		.slick-arrow {
			position: relative;
			width: 45px;
			height: 45px;
			top: 50%;
			transform: translateY(-50%);

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-repeat: no-repeat;
			}

			&:focus-visible {
				outline: 3px solid #2868b7;
			}

			&.slick-disabled {
				cursor: default;
			}
		}

		.slick-prev {
			left: 0;

			&.slick-disabled {
				&:after {
					background-image: url("/site-media/icons/icon_arrow-left-disabled.svg") !important;
				}
			}

			&:after {
				background-image: url("/site-media/icons/icon_arrow-left-active.svg") !important;
			}
		}

		.slick-next {
			right: 0;

			&.slick-disabled {
				&:after {
					background-image: url("/site-media/icons/icon_arrow-right-disabled.svg") !important;
				}
			}

			&:after {
				background-image: url("/site-media/icons/icon_arrow-right-active.svg") !important;
			}
		}

		.rotator-controls {
			display: inline-flex;
			margin-top: 35px;

			@include for-size(phone-down) {
				margin-top: 20px;
			}

			.rotator-arrows {
				display: flex;
				gap: 10px;
			}
		}

		&-item {
			width: 40vw !important;
			max-width: 750px;
			height: 100%;
			text-align: left;
			display: flex !important;

			@include for-size(tablet-landscape-down) {
				width: 75vw !important;
			}
			@include for-size(phone-down) {
				width: 70vw !important;
			}
		}
	}
}
